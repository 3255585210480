import { ReactNode } from 'react';

import { useResults } from '~/entities/Results/context/ResultsContext';
import { PRINT_CONTAINER_ID } from '~/shared/lib/html2pdf';

function Show({ children }: { children: ReactNode }) {
  const { contentRef } = useResults();
  return (
    <div ref={contentRef} id={PRINT_CONTAINER_ID} className="print">
      {children}
    </div>
  );
}

function Hide({ children }: { children: ReactNode }) {
  return <div className="no-print">{children}</div>;
}

export { Hide, Show };
