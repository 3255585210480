import { useCallback, useMemo } from 'react';
import {
  CalendarDaysIcon,
  ClipboardCheckThinIcon,
  DownloadIcon,
  GearIcon,
  HouseIcon,
  PeopleIcon,
} from '@ghq-abi/design-system-icons';
import { useTranslate } from '@tolgee/react';

import { useAbility } from '~/app/contexts/AbilityContext';
import { useFlags } from '~/app/contexts/FlagsContext';
import { RouteObject } from '~/app/types';
import { IdpIcon } from '~/shared/components/icons/IdpIcon';
import { pagesPaths, PagesPathsKeys } from '~/shared/constants/pages';

export function useMenuRoutes() {
  const { t } = useTranslate();
  const ability = useAbility();
  const flags = useFlags();

  const hasAuthorizedFlags = useCallback(
    (authorizedFlags: PagesPathsKeys | PagesPathsKeys[]) => {
      if (
        typeof authorizedFlags === 'string' &&
        !flags[authorizedFlags].active
      ) {
        return false;
      }
      if (
        Array.isArray(authorizedFlags) &&
        !authorizedFlags.some(flag => flags[flag].active)
      ) {
        return false;
      }
      return true;
    },
    [flags]
  );

  const { menuRoutes, footerRoutes } = useMemo(() => {
    const menuRoutesList: RouteObject[] = [];
    const footerRoutesList: RouteObject[] = [];

    // Common routes for all profiles
    if (hasAuthorizedFlags('home') && ability.can('view', 'Home')) {
      menuRoutesList.push({
        key: 'home',
        icon: <HouseIcon />,
        path: '/home',
        title: t('menu.home'),
      });
    }

    if (
      hasAuthorizedFlags('nominations') &&
      ability.can('view', 'Nominations')
    ) {
      menuRoutesList.push({
        key: 'nominations',
        icon: <PeopleIcon />,
        path: '/nominations',
        title: t('menu.nominations'),
      });
    }

    if (
      hasAuthorizedFlags('evaluations') &&
      ability.can('view', 'Evaluations')
    ) {
      menuRoutesList.push({
        key: 'evaluations',
        icon: <ClipboardCheckThinIcon />,
        path: '/evaluations',
        title: t('menu.assessments'),
      });
    }

    if (hasAuthorizedFlags('results') && ability.can('view', 'Results')) {
      menuRoutesList.push({
        key: 'results',
        icon: <CalendarDaysIcon />,
        path: pagesPaths.results,
        title: t('menu.my_results'),
      });
    }

    if (!hasAuthorizedFlags('results') && ability.can('view', 'Results')) {
      menuRoutesList.push({
        key: 'results',
        icon: <CalendarDaysIcon />,
        path: '/reports',
        title: t('menu.my_results'),
      });
    }

    if (hasAuthorizedFlags('idp') && ability.can('view', 'Idp')) {
      menuRoutesList.push({
        key: 'idp',
        icon: <IdpIcon />,
        path: '/idp/individual',
        title: t('menu.my_idp'),
      });
    }

    if (
      hasAuthorizedFlags('downloadDocuments') &&
      ability.can('view', 'DownloadDocuments')
    ) {
      menuRoutesList.push({
        key: 'downloadDocuments',
        icon: <DownloadIcon />,
        path: '/download-documents',
        title: t('menu.my_downloads'),
      });
    }

    // Manager and Admin specific routes
    const teamRoutes: RouteObject[] = [];

    if (
      hasAuthorizedFlags('managerApproval') &&
      ability.can('view', 'ManagerApproval')
    ) {
      teamRoutes.push({
        key: 'managerApproval',
        path: '/manager-approval',
        title: t('menu.manager_approval'),
      });
    }

    if (
      hasAuthorizedFlags('myTeamAssessmentsCompletion') &&
      ability.can('view', 'TeamAssessments')
    ) {
      teamRoutes.push({
        key: 'myTeamAssessmentsCompletion',
        path: '/my-team/assessments-completion',
        title: t('menu.my_team.assessments_completion'),
      });
    }

    if (ability.can('view', 'TeamReports')) {
      teamRoutes.push({
        key: 'teamReports',
        path: '/reports',
        title: t('menu.team_results'),
      });
    }

    if (hasAuthorizedFlags('idp') && ability.can('view', 'TeamIdp')) {
      teamRoutes.push({
        key: 'teamIdp',
        path: '/idp/my-team',
        title: t('menu.team_idp'),
      });
    }

    if (teamRoutes.length > 0) {
      menuRoutesList.push({
        key: 'myTeam',
        icon: <PeopleIcon />,
        title: t('menu.my_team'),
        path: '/my-team',
        routeComponents: teamRoutes,
      });
    }

    // Admin specific routes
    const adminRoutes: RouteObject[] = [];

    if (hasAuthorizedFlags('admin') && ability.can('view', 'Admin')) {
      adminRoutes.push({
        key: 'manageParticipants',
        path: '/admin',
        title: t('menu.manage_participants'),
      });
      adminRoutes.push({
        key: 'adminReports',
        path: '/reports',
        title: t('menu.results'),
      });
    }

    if (
      hasAuthorizedFlags(['roleSetting']) &&
      ability.can('manage', 'RoleSetting')
    ) {
      adminRoutes.push({
        key: 'roleSetting',
        path: '/role-setting',
        title: t('menu.role_settings'),
      });
    }

    if (adminRoutes.length > 0) {
      menuRoutesList.push({
        key: 'admin',
        icon: <GearIcon />,
        title: t('menu.admin'),
        path: '/admin',
        routeComponents: adminRoutes,
      });
    }

    return { menuRoutes: menuRoutesList, footerRoutes: footerRoutesList };
  }, [ability, hasAuthorizedFlags, t]);

  return { menuRoutes, footerRoutes };
}
