import Script from 'next/script';

type NpsMetricScriptProps = {
  globalId?: string;
  preferredLanguage?: string;
};

function NpsMetricScript({
  globalId,
  preferredLanguage,
}: NpsMetricScriptProps) {
  if (!globalId) return null;
  return (
    <>
      <Script
        type="text/javascript"
        src={process.env.NEXT_PUBLIC_METRICS_URL}
        strategy="afterInteractive"
        onError={() => {
          console.error('Failed to load the metrics script.');
        }}
      />
      <Script id="plugin" strategy="afterInteractive">
        {`
          function waitForMetricsPlugin(maxAttempts = 20) {
            let attempts = 0;
            const checkInterval = 1000; // Check every 1 second

            function checkPlugin() {
              if (typeof metricsPlugin !== 'undefined') {
                metricsPlugin.plugin("${globalId}","LCM","${preferredLanguage}");
                return;
              }

              attempts++;
              if (attempts < maxAttempts) {
                setTimeout(checkPlugin, checkInterval);
              } else {
                console.error('Metrics plugin failed to load after multiple attempts');
              }
            }

            checkPlugin();
          }

          waitForMetricsPlugin();
        `}
      </Script>
    </>
  );
}

export const NPS = {
  Script: NpsMetricScript,
};
