import React, { createContext, PropsWithChildren, useContext } from 'react';

import { usePrint } from '../hooks/usePrint';

const ResultsContext = createContext<ReturnType<typeof usePrint> | undefined>(
  undefined
);

export const ResultsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const value = usePrint();

  return (
    <ResultsContext.Provider value={value}>{children}</ResultsContext.Provider>
  );
};

export function useResults() {
  const context = useContext(ResultsContext);

  if (!context) {
    throw new Error('useResults() must be used within an ResultsProvider');
  }

  return context;
}
