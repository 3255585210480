import * as React from 'react';

import { pagesPaths, PagesPathsKeys } from '~/shared/constants/pages';

export type Actions = 'massSelect';

export type Flags = Record<PagesPathsKeys | Actions, { active: boolean }>;

type FlagsContextData = {
  flags: Flags;
  skipCheck: boolean;
};

const FlagsContext = React.createContext({} as FlagsContextData);

type FlagsProviderProps = {
  children: React.ReactNode;
  flags?: Partial<Flags>;
  skipCheck?: boolean;
};

export function FlagsProvider({
  children,
  flags,
  skipCheck = false,
}: FlagsProviderProps) {
  const providerValue = React.useMemo(
    () => ({
      flags: {
        ...Object.keys(pagesPaths).reduce(
          (acc, curr) => ({ ...acc, [curr]: { active: true } }),
          {} as Flags
        ),
        ...flags,
      },
      skipCheck,
    }),
    [flags, skipCheck]
  );

  return (
    <FlagsContext.Provider value={providerValue}>
      {children}
    </FlagsContext.Provider>
  );
}

export function useFlags() {
  const context = React.useContext(FlagsContext);

  if (!context) {
    throw new Error('useFlags must be used within a FlagsProvider');
  }

  return context.flags;
}

type MountFlagsOptions = {
  skipCheck?: boolean;
};

export function mountFlags(options: MountFlagsOptions = {}): Flags {
  if (options.skipCheck) {
    return Object.keys(pagesPaths).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: { active: true },
      }),
      {}
    ) as Flags;
  }

  return {
    home: {
      active: process.env.PAGE_HOME_ENABLED !== 'false',
    },
    nominations: {
      active: process.env.PAGE_NOMINATIONS_ENABLED !== 'false',
    },
    admin: {
      active: process.env.PAGE_ADMIN_ENABLED !== 'false',
    },
    proxy: {
      active: process.env.PAGE_PROXY_ENABLED !== 'false',
    },
    managerApproval: {
      active: process.env.PAGE_MANAGER_APPROVAL_ENABLED !== 'false',
    },
    downloadDocuments: {
      active: process.env.PAGE_DOWNLOAD_DOCUMENTS_ENABLED !== 'false',
    },
    evaluations: {
      active: process.env.PAGE_EVALUATION_ENABLED !== 'false',
    },
    reports: {
      active: process.env.PAGE_REPORTS_ENABLED !== 'false',
    },
    roleSetting: {
      active: process.env.PAGE_ROLE_SETTING_ENABLED !== 'false',
    },
    idp: {
      active: process.env.PAGE_IDP_ENABLED !== 'false',
    },
    massSelect: {
      active: process.env.PAGE_ADMIN_MASS_SELECT_ENABLED !== 'false',
    },
    myTeamAssessmentsCompletion: {
      active: process.env.PAGE_MY_TEAM_ASSESSMENTS_COMPLETION !== 'false',
    },
    results: {
      active: process.env.PAGE_RESULTS !== 'false',
    },
  };
}

type ShouldBlockAccessOptions = {
  skipCheck?: boolean;
};

export function shouldBlockAccess(
  path: string,
  options: ShouldBlockAccessOptions = {}
) {
  if (options.skipCheck) {
    return false;
  }

  const flags = mountFlags({ skipCheck: options.skipCheck });

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(pagesPaths)) {
    if (typeof value === 'string' && path === value) {
      return !flags[key as PagesPathsKeys].active;
    }

    // if (value instanceof RegExp && value.test(path)) {
    //   return !flags[key as PagesPathsKeys].active;
    // }
  }

  return false;
}
