import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { peoplePlatformApi } from '~/shared/services/api';

async function getProfilePicture(globalId?: string) {
  try {
    const { data } = await peoplePlatformApi.get<Blob>(
      `/photo/thumb/${globalId}`,
      {
        responseType: 'blob',
      }
    );

    return data;
  } catch {
    return null;
  }
}

export function useGetProfilePicture(globalId: string) {
  const query = useQuery({
    queryKey: ['getProfilePicture', globalId],
    queryFn: () => getProfilePicture(globalId),
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const imageSrc = useMemo(
    () => (query?.data ? URL.createObjectURL(query.data) : null),
    [query.data]
  );
  return Object.assign(query, {
    imageSrc,
  });
}
