export const pagesPaths = {
  home: '/home',
  nominations: '/nominations',
  admin: '/admin',
  managerApproval: '/manager-approval',
  downloadDocuments: '/download-documents',
  proxy: '/proxy',
  evaluations: '/evaluations',
  reports: '/reports',
  roleSetting: '/role-setting',
  idp: '/idp/individual',
  myTeamAssessmentsCompletion: '/my-team/assessments-completion',
  results: '/results',
} as const;

export type PagesPathsKeys = keyof typeof pagesPaths;
