// TODO: Maybe we should export the sanitizer from de DS
import sanatize from 'sanitize-html';

import { DEFAULT_LANGUAGE } from '../constants/i18n';
import { Nullable } from '../types/Base';

export const sanitizerAllowedConfig: sanatize.IOptions = {
  allowedTags: ['div', 'b', 'i', 'u', 'br'],
  allowedAttributes: {},
  allowedIframeHostnames: [],
};

export function capitalize(str: string) {
  return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
}

export function clipAndAddEllipsis(text: string, size: number, noBreak = true) {
  const textCounter = noBreak ? text.replace(/(\r\n|\n|\r)/gm, '') : text;
  return textCounter.length > size
    ? text.substring(0, size - 3).concat('...')
    : text;
}

export function sanitizeHtml(dirty: string, config = sanitizerAllowedConfig) {
  return sanatize(dirty, config);
}

export function parseString(value: unknown) {
  return String(value) ?? null;
}

export function removeSpaces(text: string) {
  return text.replace(/ {2,}/g, ' ').trimStart().trimEnd();
}

export function normalizeLanguage(lang: string) {
  const preferredLanguage = typeof lang === 'string' ? lang : DEFAULT_LANGUAGE;

  return preferredLanguage.replace('_', '-');
}

export function camelCaseToSnakeCase(str?: Nullable<string>) {
  if (!str) return str;

  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export const cleanTolgeeText = (str: string) =>
  str
    .normalize('NFC') // Normalize Unicode (fixes encoding issues)
    .trim() // Remove extra spaces
    // eslint-disable-next-line no-misleading-character-class
    .replace(/[\u200B\u200C\u200D\uFEFF]/g, '') // Remove Zero-Width & BOM chars
    .replace(/\u00A0/g, ' ') // Replace non-breaking spaces with normal spaces
    .replace(/\s+/g, ' '); // Collapse multiple spaces
