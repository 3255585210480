import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { LcmUserSession } from '../auth/types/next-auth';
import { formatRolesToString } from '../utils/role';

export function useSetupClarityTracking(
  locale: string,
  session?: LcmUserSession
) {
  const router = useRouter();
  const user = session?.user?.proxiedAs ?? session?.user;

  useEffect(() => {
    if (typeof window === 'undefined' || !window.clarity) return;

    function addTag(tag: string, value?: string | string[]) {
      if (!window.clarity) return;

      window.clarity('set', tag, value);
    }

    function initTagConfig(userSession: LcmUserSession['user']) {
      if (!window.clarity) return;

      addTag('employeeId', userSession.employeeId);
      addTag('zone', userSession.zone);
      addTag('band', userSession.band);
      addTag('country', userSession.country);
      addTag('role', formatRolesToString(userSession.roles));
    }

    if (user) {
      initTagConfig(user);
    }
  }, [locale, user]);

  useEffect(() => {
    function trackPageChange(lcmUser: LcmUserSession['user'], url: string) {
      if (!window.clarity) return;

      window.clarity('identify', lcmUser.employeeId, null, url);
    }

    if (user) {
      trackPageChange(user, router.pathname);
    }

    router.events.on('routeChangeComplete', trackPageChange);

    return () => {
      router.events.off('routeChangeComplete', trackPageChange);
    };
  }, [router, user]);
}

export function fireEvent(eventName: string) {
  if (typeof window === 'undefined' || !window.clarity) {
    return;
  }

  window?.clarity('event', eventName);
}
