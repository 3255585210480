import { Avatar } from '../Avatar';

import { Actions } from './Actions';
import { AssessmentType } from './AssessmentType';
import { Content } from './Content';
import { ProfileBadge } from './ProfileBadge';
import { ProfileGroup } from './ProfileGroup';
import { ProfileRoot } from './ProfileRoot';

export const EmployeeProfile = {
  Root: ProfileRoot,
  Actions,
  Badge: ProfileBadge,
  AssessmentType,
  Content,
  Group: ProfileGroup,
  Avatar,
};
