import { useCallback, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
// TODO understand why eslint is not working with this import
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import html2pdf from 'html2pdf.js';

// TODO refact this hook when all pages are finished
export function usePrint() {
  const contentRef = useRef<HTMLDivElement>(null);

  const [isPrinting, setIsPrinting] = useState(false);

  const reactToPrintFn = useReactToPrint({
    contentRef,
    documentTitle: 'document.pdf',
    async print(printFrame) {
      const doc = printFrame.contentDocument;
      if (doc) {
        const html = doc.getElementsByTagName('html')[0];

        const style = document.createElement('style');
        style.textContent = `body {background: #fff;}`;
        doc.head.appendChild(style);

        const elementsToHideDuringPrint = doc.querySelectorAll('.no-print');

        elementsToHideDuringPrint.forEach(element => {
          element.classList.add('no-display');
        });

        const options = {
          margin: 4,
          filename: 'document.pdf',
          image: { type: 'jpeg', quality: 1 },
          html2canvas: {
            scale: 2,
          },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'l' },
        };

        html2pdf()
          .from(html)
          .set(options)
          .save()
          .catch((err: unknown) => console.error(err))
          .finally(() => {
            elementsToHideDuringPrint.forEach(element => {
              element.classList.remove('no-display');
              setIsPrinting(false);
            });
          });
      }
    },
  });
  const onPrint = useCallback(() => {
    setIsPrinting(true);
    setTimeout(() => reactToPrintFn(), 1000);
  }, [reactToPrintFn]);

  return useMemo(
    () => ({
      isPrinting,
      onPrint,
      contentRef,
    }),
    [isPrinting, onPrint, contentRef]
  );
}
