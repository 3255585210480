import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import type { LcmUserSession } from '../auth/types/next-auth';
import { formatRolesToString } from '../utils/role';

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID ?? '';

type EventsEntities =
  | 'Nominations'
  | 'Evaluation'
  | 'Manager_Approval'
  | 'Admin'
  | 'Report';

type AnalyticEvents =
  | 'Evaluation_access'
  | 'Evaluation_edit'
  | 'Evaluation_decline_click'
  | 'Evaluation_declined_confirmation'
  | 'Evaluation_edit_assessment'
  | 'Evaluation_submit_assessment'
  | 'Evaluation_save_close'
  | 'Evaluation_rating_scale'
  | 'Evaluation_review'
  | 'Evaluation_last_year_evaluation'
  | 'Evaluation_take_survey'
  | 'Evaluation_overview_click'
  | 'Evaluation_overview_edit'
  | 'Nominations_save'
  | 'Nominations_submit'
  | 'Nominations_toolkit'
  | 'Nominations_edition'
  | 'Nominations_access'
  | 'Manager_Approval_submit'
  | 'Manager_Approval_feedback'
  | 'Manager_Approval_approval'
  | 'Manager_Approval_access'
  | 'Report_auto_download_self_pdf'
  | 'Admin_add_on_process'
  | 'Admin_mass_remove_process'
  | 'Admin_mass_change_from_180_to_360'
  | 'Admin_mass_change_from_360_to_180'
  | 'Admin_access_rater_list'
  | 'Admin_download_my_scope_report'
  | 'Admin_download_idp_report'
  | 'Admin_edit_participant_raters_add_click'
  | 'Admin_edit_participant_raters_add_save'
  | 'Admin_edit_participant_raters_change_from_180_to_360_click'
  | 'Admin_edit_participant_raters_change_from_180_to_360_save'
  | 'Admin_edit_participant_raters_change_from_360_to_180_click'
  | 'Admin_edit_participant_raters_change_from_360_to_180_save'
  | 'Admin_edit_participant_raters_mass_remove_click'
  | 'Admin_edit_participant_raters_mass_remove_save'
  | 'Admin_edit_participant_raters_save'
  | 'Admin_edit_participant_raters_undo'
  | 'Admin_edit_participant_raters_change_nomination_type_click'
  | 'Admin_edit_participant_raters_change_nomination_type_save';

type GTagEvent = {
  action: AnalyticEvents;
  category: EventsEntities;
  label: string;
  value: number;
};

function fireEvent({ action, category, label, value }: GTagEvent) {
  if (!GA_TRACKING_ID) return;

  window.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
}

function useUserProperties(session?: LcmUserSession | null) {
  useEffect(() => {
    if (!session) return;

    window.gtag?.('set', 'user_properties', {
      country: session.user.country,
      employeeId: session.user.employeeId,
      zone: session.user.zone,
      band: session.user.band,
      roles: formatRolesToString(session.user.roles),
    });
  }, [session]);
}

const pageview = (url: string) => {
  if (!GA_TRACKING_ID) return;

  window.gtag?.('config', GA_TRACKING_ID, {
    page_path: url,
    page_title: document.title,
  });
};

function usePageView() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router.events]);
}

type GoogleAnalyticsScriptProps = {
  session?: LcmUserSession | null;
};

function GoogleAnalyticsScript({ session }: GoogleAnalyticsScriptProps) {
  usePageView();
  useUserProperties(session);

  if (!GA_TRACKING_ID || !session) return null;

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script id="gtag-init" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_TRACKING_ID}', {
            page_path: window.location.pathname,
            page_title: document.title,
          });
        `}
      </Script>
    </>
  );
}

export const GA = {
  fireEvent,
  Script: GoogleAnalyticsScript,
};
